import ContentBlockStoreFinder from 'components/contentBlocks/ContentBlockStoreFinder';
import SubscriptionForm from 'components/footer/SubscriptionForm';
import baseInitialize from './base';

baseInitialize();

ContentBlockStoreFinder.attachTo('.js-contentBlock--storeFinder');

SubscriptionForm.attachTo('.js-outlet-newsletter-form', {
  checkboxSelector: '.js-formInputGroup--checkbox input[type=checkbox]',
  emailSelector: '.js-formInputGroup--email input',
  buttonSelector: '.js-loginForm-colFooter button',

  checkboxBlockSelector: '.js-formInputGroup--checkbox',
  fieldsContainerSelector: '.js-newsletter-form-fields',
  responseSelector: '.js-newsletter-form-response',
  emailBlockSelector: '.js-formInputGroup--emailFooter'
});
