/* global AppSettings */
import $ from 'jquery';
import Factory from 'components/utils/Factory';
import MapManager from 'components/storeFinder/MapManager';
import Data from 'components/storeFinder/Data';
import SelectboxSearchManager from 'components/storeFinder/SelectboxSearchManager';
import EventTypes from 'components/EventTypes';
import createLogger from 'components/logger/Logger';
import 'jquery.extendPrototype';

const mapLoaders = {
  cn: () => {
    return import(/* webpackChunkName: "import--sogouMap" */ 'components/storeFinder/API/sogouMap');
  },
  world: () => {
    return import(
      /* webpackChunkName: "import--googleMap" */ 'components/storeFinder/API/googleMap'
    );
  },
};

const Logger = createLogger('ContentBlockStoreFinder');
const $document = document.jq;

function ContentBlockStoreFinder() {
  Logger.info('Created ContentBlockStoreFinder');
  Logger.time('initialize', this);

  this.$storeFinder = $('.js-storeFinder');

  if (!this.$storeFinder.length) {
    return;
  }

  this.initialize();

  this.bindEvents();

  this.$storeFinder.trigger(EventTypes.STOREFINDER_INITIALIZED);
}

$.extendPrototype(ContentBlockStoreFinder, {
  $storeFinder: undefined,
  bindEvents() {
    $document
      .one(EventTypes.STOREFINDER_MAP_INITIALIZED, this.onMapInitialized.bind(this))
      .one(EventTypes.STOREFINDER_MAP_FAILED, this.onMapFailed.bind(this))
      .on(EventTypes.STOREFINDER_GET_STORES, this.onGetStores.bind(this))
      .on(EventTypes.STOREFINDER_GET_STORES_REQUEST, this.onGetStoresRequest.bind(this));
  },
  initialize() {
    let mapLoaderFn;
    if (AppSettings.country.toLowerCase() === 'cn') {
      mapLoaderFn = mapLoaders.cn;
    } else {
      mapLoaderFn = mapLoaders.world;
    }

    mapLoaderFn().then(this.initMap.bind(this));

    this.$storeFinder.addClass('is-loading-data');

    Data.initialize({
      useUserLocation: false,
    });

    SelectboxSearchManager.attachTo('.js-storeFinder--dropdownSearch');
  },
  onGetStores() {
    this.$storeFinder.removeClass('is-loading-data');
  },
  onGetStoresRequest() {
    this.$storeFinder.addClass('is-loading-data');
  },
  onMapFailed() {
    this.$storeFinder.removeClass('is-loading').addClass('has-error');
    this.$storeFinder
      .find('.js-storeFinder-bar-optionsBar')
      .html(window.labels.storeFinder.errorMessageLoad);
  },
  onMapInitialized() {
    this.$storeFinder.removeClass('is-loading');
  },

  initMap(mapApi) {
    const settings = {
      Map: mapApi.default,
      $storeFinder: this.$storeFinder,
      fullPage: false,
      breakpointOverlay: 'xxsmall',
      draggable: true,
    };

    MapManager.initialize(settings);
  },
});

export default Factory.create(ContentBlockStoreFinder, { logger: Logger });
