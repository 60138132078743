/* global AppSettings */
/**
 * StoreFinderData
 *
 * @author  Meinaart van Straalen
 **/
import $ from 'jquery';
import EventTypes from 'components/EventTypes';
import createLogger from 'components/logger/Logger';
import Singleton from 'components/utils/Singleton';
import GPSUtils from 'components/utils/GPS';
import 'jquery.extendPrototype';
import 'g-star/jquery/uuid';

const Logger = createLogger('Data');

const MAX_NEAR_RESULTS = window?.AppSettings?.storeFinderMaxResultsLimit || 10;

const labels = window.labels.storeFinder;
const DISTANCE_UNIT = labels && labels.distanceUnit && labels.distanceUnit.toLowerCase();

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const date = new Date();
const dayNum = date.getDay();
const dayName = days[dayNum];
const currentTime = parseInt(
  `${date.getHours()}${date
    .getMinutes()
    .toString()
    .replace(/^([0-9])$/, '0$1')}`,
  10
);

function StoreFinderData(settings) {
  Logger.info('Created');
  this.settings = $.extend({}, this.settings, settings || {});
  this.bindEvents();
}

$.extendPrototype(StoreFinderData, {
  filters: undefined,

  geoLocationErrors: {
    1: 'PERMISSION_DENIED',
    2: 'POSITION_UNAVAILABLE',
    3: 'REQUEST_TIMEOUT',
  },

  geoLocationSettings: {
    maximumAge: 60000,
    timeout: 10000,
  },

  // If an user does not answer the geoLocation permission
  // how long should we take to consider the response as not coming.
  // Good example: user clicking "not now" in Firefox
  geoLocationTimeoutDialog: 10000,

  settings: {
    url:
      (AppSettings.endpoints && AppSettings.endpoints.storeFinder) ||
      '//apps.g-star.com/api/v1/jsonp',
    useUserLocation: true,
  },

  stores: [],
  userLocation: undefined,

  /**
   * Bind event listeners
   */
  bindEvents() {
    document.jq
      .on(EventTypes.STOREFINDER_MAP_STORES_UPDATED, this.onMapStoresUpdated.bind(this))
      .on(EventTypes.STOREFINDER_GET_STORES_REQUEST, this.onGetStoresRequest.bind(this))
      .on(EventTypes.STOREFINDER_GET_USER_LOCATION_REQUEST, this.getUserLocation.bind(this))
      .on(EventTypes.STOREFINDER_GET_USER_LOCATION, this.onGetUserLocation.bind(this))
      .on(EventTypes.STOREFINDER_STORES_FILTER_REQUEST, this.onFilterRequest.bind(this));
  },

  /**
   * Check if store matches filter, result is set in the `visible` property of the store.
   *
   * @param  {object} filters Object containing `storeFinderCategory` property which has
   *                         an array of strings to match `storeFinderCategory` against.
   * @param  {object} store  Object containing store data
   * @returns {boolean} Indicating that it has matched
   */
  filterStore(filters, store) {
    let passed = true;
    if (filters) {
      for (const key in filters) {
        const filterValues = filters[key];
        if (filterValues.length && store[key]) {
          passed = filterValues.some(checkFilterMatch.bind(this, store[key]));
        }
      }
    }
    return passed;
  },

  /**
   * Enriches store object with formatted address
   *
   * @param  {object} store Object with store data
   * @return {object}       Enriched store object
   */
  formatAddress(store) {
    const storeName = store.name;
    const storeId = store.store_id;
    const { address } = store;
    const postCode = store.zip_code;
    const { city } = store;
    const phoneNumber = store.telephone_number;
    const fullAddress =
      (address ? address : '') + (postCode ? `, ${postCode}` : '') + (city ? `, ${city}` : '');
    const contentString =
      `<p data-id="${storeId}" class="main-block-store">` +
      `<span class="address-big">${storeName}<span class="icon-close"></span></span>` +
      `<span class="address-small">${fullAddress}</span>${
        phoneNumber
          ? `<a href="tel:${phoneNumber.replace(
              '(0)',
              ''
            )}" class="main-block-store--phone">${phoneNumber}</a><br/>`
          : ''
      }</p>`;

    store.fullAddress = fullAddress;
    store.addressHtml = contentString;

    return store;
  },

  /**
   * Retrieve stores from server.
   *
   * @param  {object} data Object with request data
   *                       General:
   *                       - country (String, countryCode, defaults to current country)
   *                       - details (Boolean)
   *                       - storeType (String, 4 characters, MULT, FOOT, MONO)
   *
   *                       Searching by coordinates:
   *                       - latitude (String)
   *                       - longitude (String)
   *                       - maxDistance (Number, defaults to 25000)
   *                       - maxResults (Number, defaults to 10)
   */
  getStoresData(data) {
    Logger.debug('Loading stores');
    data = data || {};
    let url;
    let requestData;

    // stocklocator request with product ID
    if (data.productId) {
      url = this.getStoresStockDataUrl(data);
      requestData = {
        country_iso: AppSettings.country,
      };
    } else {
      let endPoint = AppSettings.endpoints.getStores;
      requestData = $.extend({}, data, {
        country_iso: data.country || data.country_iso || AppSettings.country,
        output_details: data.details === false ? '0' : '1',
      });

      delete requestData.details;
      delete requestData.country;

      if (requestData.longitude && requestData.latitude) {
        endPoint = AppSettings.endpoints.getShopsNearMe;

        requestData = $.extend(
          {
            maxResults: MAX_NEAR_RESULTS,
          },
          requestData
        );

        if (!requestData.maxDistance) {
          const appMax = AppSettings.mapsMaxDistanceToStore;
          requestData.maxDistance = appMax && appMax.address ? appMax.address : 25000;
        }

        if (!data.country) {
          delete requestData.country_iso;
        }
      }

      if (requestData.preferredStore) {
        endPoint = AppSettings.endpoints.getPreferredStores;
        requestData.country_iso = AppSettings.country;
        requestData.limitStores = true;
        delete requestData.preferredStore;
      }

      url = `${this.settings.url}/${endPoint}?${$.param(requestData)}`;
    }

    Logger.debug('Loading data:', url);

    $.ajax({
      cache: true,
      dataType: 'json',
      method: 'GET',
      url,
    })
      .done(
        function (data) {
          const result = data.results ? data.results : data.stores;

          if (result) {
            this.stores = result.filter(function (store) {
              const category = store.storeFinderCategory;
              return (
                (category && category.toLowerCase() !== 'webshop' && !category.match(/online/gi)) ||
                true
              );
            });

            this.stores.forEach(this.processStore.bind(this));
            if (this.userLocation) {
              Logger.debug('Adding distance to stores', this.userLocation);

              GPSUtils.setDistances(this.userLocation, this.stores, DISTANCE_UNIT);
              GPSUtils.sortOnDistance(this.stores);

              // Put first G-Star Store on top
              for (let i = 0; i < this.stores.length; i++) {
                const store = this.stores[i];
                if (store.isBrandStore) {
                  this.stores.splice(i, 1);
                  this.stores.unshift(store);
                  break;
                }
              }
            }
          } else {
            this.stores = [];
          }

          let items = this.stores;
          if (this.filters) {
            items = this.stores.filter(this.filterStore.bind(this, this.filters));
          }

          const _data = {
            stores: items,
            numResults: items.length,
            filterResults: data.filterResults,
          };

          if (items.length === 0) {
            if (requestData.longitude && requestData.latitude) {
              _data.itemsNotFoundCoords = {
                lat: requestData.latitude,
                lng: requestData.longitude,
              };
            } else if (requestData.country_iso === AppSettings.country) {
              _data.country = AppSettings.countryName;
            }
          }
          document.jq.trigger(EventTypes.STOREFINDER_GOT_STORES_DATA, _data);
        }.bind(this)
      )
      .fail(function (data, status, message) {
        Logger.warn(`Failed to retrieve stores. Reason: ${message}`);
        document.jq.trigger(EventTypes.STOREFINDER_GOT_STORES_DATA, {
          stores: [],
          numResults: 0,
          filterResults: data.filterResults,
        });
      });
  },

  getStoresStockDataUrl(data) {
    const storesUrl = AppSettings.endpoints.stockLocator;
    const url = `${storesUrl}?sku=${data.productId}&country=${data.country}`;
    return url;
  },

  /**
   * Get user location (GPS)
   */
  getUserLocation() {
    Logger.debug('getUserLocation');
    if (!this.settings.useUserLocation) {
      Logger.debug('getUserLocation - bail out because of settings.useUserLocation');
      return;
    }

    const failCallback = this.onUserLocationError.bind(this, 2);

    if (navigator.geolocation) {
      // Fix for when user chooses "not now" (Firefox) or similar option (CON-823)
      // https://bugzilla.mozilla.org/show_bug.cgi?id=675533
      const timeoutId = setTimeout(
        this.onUserLocationError.bind(this, undefined),
        this.geoLocationTimeoutDialog
      );

      try {
        navigator.geolocation.getCurrentPosition(
          this.onUserLocation.bind(this, timeoutId),
          this.onUserLocationError.bind(this, timeoutId),
          this.geoLocationSettings
        );
      } catch (e) {
        clearTimeout(timeoutId);
        failCallback();
      }
    } else {
      failCallback();
    }
  },

  /**
   * Handle request for filtering stores.
   *
   * @param  {object} event     jQuery event object
   * @param  {object} eventData Event object containing `filter` property.
   */
  onFilterRequest(event, eventData) {
    Logger.debug(
      'onFilterRequest',
      eventData.filters,
      'current number of stores:',
      this.stores.length
    );
    this.filters = eventData.filters;
    let filteredStores = this.stores;
    if (this.filters) {
      filteredStores = this.stores.filter(this.filterStore.bind(this, this.filters));
    }
    const numResults = filteredStores.length;
    Logger.debug('Number of filtered results:', numResults, filteredStores);

    document.jq.trigger(EventTypes.STOREFINDER_STORES_FILTERED, {
      stores: filteredStores,
      filters: eventData.filters,
      numResults,
    });
  },

  /**
   * Listener for when stores are updated on map
   * @param  {jQuery.Event} event
   * @param  {Object} eventData Object containing `data` property
   */
  onMapStoresUpdated(event, eventData) {
    Logger.debug('onMapStoresUpdated', eventData);
    this.stores = eventData.stores;
    let filteredStores = this.stores;
    if (eventData.filters) {
      filteredStores = this.stores.filter(this.filterStore.bind(this, this.filters));
    }
    document.jq.trigger(EventTypes.STOREFINDER_DATA_STORES_UPDATED, { stores: filteredStores });
  },

  /**
   * Handle request for retrieval of stores
   *
   * @param  {Function} callback  Function to call when event is triggered
   * @param  {Object}   event     jQuery event object
   * @param  {object}   eventData Data belonging to event
   */
  onGetStoresRequest(event, eventData) {
    Logger.debug('onGetStoresRequest');
    this.getStoresData(eventData);
  },

  /**
   * Event listener for global `EventTypes.STOREFINDER_GET_USER_LOCATION` event
   *
   * @param  {Object}   event     jQuery event object
   * @param  {object}   eventData Data belonging to event, should contain property `coordinates`
   */
  onGetUserLocation(event, eventData) {
    if (eventData.coordinates) {
      this.userLocation = eventData.coordinates;
    }
  },

  /**
   * Event listener for when user location is returned by browser
   *
   * @param  {object} position Object containing users GPS coordinates
   */
  onUserLocation(timeoutId, position) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    if (position && position.coords) {
      document.jq.trigger(EventTypes.STOREFINDER_GET_USER_LOCATION, {
        position,
        coordinates: position.coords,
        fromGPS: true,
      });
    }
  },

  /**
   * Event listener for when getting user location fails
   *
   * @param  {int} errorCode Error code
   */
  onUserLocationError(timeoutId, errorCode) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    document.jq.trigger(EventTypes.STOREFINDER_GET_USER_LOCATION_ERROR, {
      error: this.geoLocationErrors[errorCode],
    });
  },

  /**
   * Process store data, add missing metadata.
   *
   * @param  {object} store Object with store data
   * @return {object}       Object with processed store data
   */
  processStore(store) {
    store.short_name = store.name;

    if (store.name1) {
      store.name = store.name1;
      delete store.name1;
    }

    store.openToday = store[dayName];
    store.todayIs = dayName;
    store.selectButton = window.labels?.storeFinder?.selectButton;

    // Check if still is indeed still open (checking times)
    // otherwise add `store.openTommorow` containing first upcoming open time
    if (store.openToday) {
      let till;

      if (store.openToday.indexOf(';') > -1) {
        till = store.openToday.split(';')[1].split('-')[1];
      } else {
        till = store.openToday.split('-')[1];
      }

      if (till) {
        // Need to handle 18:00, 9:00pm, 9PM

        // am, pm or empty
        const dayPart = till.replace(/[0-9: ]/g, '').toLowerCase();

        // Make sure `tillTime` is formatted like `9:30`, (removes "am" and/or "pm" from `till`)
        // add `:00` if `till` without am/pm just contains a single digit
        const tillTime = till.replace(/[a-z]/gi, '').replace(/^([0-9])$/, '$1:00');
        let closeTime;
        if (dayPart === 'pm') {
          // Reformat time to 24-hour format
          const times = tillTime.split(':');
          const hour = parseInt(times[0], 10) + 12;
          const minutes = parseInt(times[1], 10);
          closeTime = parseInt(`${hour}${minutes < 10 ? `0${minutes}` : minutes}`, 10);
        } else {
          closeTime = tillTime.replace(/\D*/g, '');
        }

        // Check if store is already closed,
        // if so look up the next day it's open (probably tommorow)
        if (currentTime > closeTime) {
          store.openToday = undefined;

          // Find next open time
          let t = (dayNum + 1) % 7;
          let tName;
          while (!store.openTommorow) {
            tName = days[t];
            if (store[tName]) {
              store.openTommorow = `${labels[tName]}: ${store[tName]}`;
            }
            t = (t + 1) % 7;
            if (t === dayNum) {
              break;
            }
          }
        }
      }
    }

    // Add line break to 2 shift opening hours
    function addLineBreak(str) {
      return (str && str.replace(/;/g, '<br>')) || '';
    }

    store.monday = addLineBreak(store.monday);
    store.tuesday = addLineBreak(store.tuesday);
    store.wednesday = addLineBreak(store.wednesday);
    store.thursday = addLineBreak(store.thursday);
    store.friday = addLineBreak(store.friday);
    store.saturday = addLineBreak(store.saturday);
    store.sunday = addLineBreak(store.sunday);
    store.openToday = addLineBreak(store.openToday);

    store.store_id = store.store_id || $.generateUUID();

    store.isBrandStore = !!(store.storeFinderCategory && store.storeFinderCategory.match(/mono/gi));
    store.isOutletStore = store.storeType && store.storeType.toLowerCase() === 'outlet';
    return this.formatAddress(store);
  },
});

/**
 * Check if `storeValue` contains `filterValue`.
 *
 * @param  {string} storeValue   Value from store object
 * @param  {string} filterValue Value to check against
 * @return {boolean}            Does `storeValue` contain `filterValue`
 */
function checkFilterMatch(storeValue, filterValue) {
  const regexp = new RegExp(filterValue, 'ig');

  return storeValue && regexp.test(storeValue);
}

export default Singleton.create(StoreFinderData, 'StoreFinderData');
