/**
 * Store finder as shown on outlet making use of 2 dropdowns. One contains
 * countries, the other contains the stores in that country.
 *
 * @author Vincent Bruijn
 */

import $ from 'jquery';
import Factory from 'components/utils/Factory';
import createLogger from 'components/logger/Logger';
import EventTypes from 'components/EventTypes';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import storeOpeningTimeTemplate from './templates/storeOpeningTime.html';
import Mustache from 'mustache';
import 'jquery.extendPrototype';

const Logger = createLogger('SelectboxSearchManager');
const $document = document.jq;

let template = '<option value="">All stores</option>';
template += '{{#stores}}<option value="{{store_id}}">{{name}}</option>{{/stores}}';


const mobileStoreInfoContentBlockTemplate =
  '<div class="storeFinder-map--storeInfo js-storeFinder-map--storeInfo"><strong>{{name}}</strong><br>{{address.streetname}}, {{address.streetnumber}}<br>{{address.postalCode}} {{address.city}}<br>{{address.country}}</div>';

let previousSelectedStore;

function SelectboxSearchManager($element, settings) {
  settings = settings || {};
  settings.selector = $element;

  this.bindEvents();
}

$.extendPrototype(SelectboxSearchManager, {
  settings: {
    selector: undefined,
    storeDropdownSelector: '.js-storeFinder-stores',
  },
  stores: null,
  bindEvents() {
    $('.js-storeFinder-countries').on('change', this.onCountryChange.bind(this));
    $('.js-storeFinder-stores').on('change', this.onStoreChange.bind(this));
    $document
      .on(EventTypes.STOREFINDER_GOT_STORES_DATA, this.onGotStores.bind(this))
      .on(EventTypes.STOREFINDER_STORE_DETAILS_OPEN_REQUEST, this.onStoreOpenRequest.bind(this));

    $document.one(EventTypes.STOREFINDER_MAP_INITIALIZED, function () {
      $('.js-storeFinder-countries').trigger('change');
    });
  },
  onCountryChange(event) {
    this.stores = null;
    const countryCode = $(event.target).val();
    $document.trigger(EventTypes.STOREFINDER_GET_USER_LOCATION, {
      country: countryCode,
    });
    $('.js-storeFinder-openinghours').html('');
  },
  onStoreChange(event, eventData) {
    const fromList = (eventData && eventData.fromList) || true;
    const storeId = $(event.target).val();

    const detailData = {
      store: undefined,
      isSelected: true,
      fromList,
      fromMap: !fromList,
    };

    if (!storeId) {
      detailData.isSelected = false;
      detailData.store = previousSelectedStore;
      $document.trigger(EventTypes.STOREFINDER_STORE_DETAILS_CLOSE, detailData);

      this.updateStoreInfoBlock('');
      return;
    }

    const store = this.getStoreFromList(storeId);

    detailData.store = store;

    $document.trigger(EventTypes.STOREFINDER_STORE_DETAILS_OPEN, detailData);

    this.renderStoreInfo(store);
    previousSelectedStore = store;
  },
  onGotStores(event, stores) {
    const options = Mustache.render(template, stores);
    $('.js-storeFinder-stores').html(options);

    this.stores = stores;
  },
  onStoreOpenRequest(event, eventData) {
    if (!eventData.fromMap) {
      return;
    }

    const storeId = eventData.marker.store_id;
    const store = this.getStoreFromList(storeId);

    $('.js-storeFinder-stores').val(storeId);

    this.renderStoreInfo(store);
    previousSelectedStore = store;
  },
  updateStoreInfoBlock(html) {
    $('.js-storeFinder-openinghours').html(html);
  },
  renderStoreInfo(store) {
    const openingTime = {
      monday: store.monday,
      tuesday: store.tuesday,
      wednesday: store.wednesday,
      thursday: store.thursday,
      friday: store.friday,
      saturday: store.saturday,
      sunday: store.sunday,
    };

    for (const key in openingTime) {
      const openingTimeValues = openingTime[key];
      if (key === store.todayIs) {
        const newPropertyName = `${key}IsActive`;
        openingTime[newPropertyName] = true;
      }

      if (openingTimeValues.length) {
        openingTime.visible = true;
      }
    }

    openingTime.labels = window.labels?.storeFinder || {};

    const storeOpeningTimeContent = Mustache.render(storeOpeningTimeTemplate, openingTime);

    this.updateStoreInfoBlock(storeOpeningTimeContent);

    // mobile specific
    if (!matchBreakpoint('xsmall')) {
      $('.js-storeFinder-details-list').activate();

      const storeInfo = Mustache.render(mobileStoreInfoContentBlockTemplate, store);
      $('.js-storeFinder-map--storeInfo').remove();
      $('.js-contentBlock--storeFinder').append(storeInfo);
    }
  },
  getStoreFromList(storeId) {
    return this.stores.stores
      .filter(function (el, idx) {
        return el.store_id === storeId;
      })
      .pop();
  },
});

export default Factory.create(SelectboxSearchManager);
