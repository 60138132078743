/**
 * Base object for StoreFinder components.
 *
 * @author Meinaart van Straalen
 */
import $ from 'jquery';
import EventTypes from 'components/EventTypes';
import createLogger from 'components/logger/Logger';
import 'jquery.extendPrototype';
const Logger = createLogger('StoreFinderComponent');
  function StoreFinderComponent(settings, module) {
    this.settings = $.extend({}, this.settings, settings);

    if (this.settings.selector) {
      this.$element = this.findStoreFinderElement(this.settings.selector);
    }

    if (this.settings.$storeFinder) {
      this.$storeFinder = this.settings.$storeFinder;
    }

    if (module) {
      this.module = module;
    }
  }

  $.extendPrototype(StoreFinderComponent, {
    $element: undefined,
    module: {},
    settings: {
      breakpointOverlay: 'medium',
    },

    /**
     * Find DOM element that is a child of `$parent` or if omited of `this.$element`.
     * If `this.$element` is not set the selector is found globally (`$(selector)`).
     *
     * @param  {string} selector Selector
     * @param  {jQuery} $parent  jQuery object, if omited `this.$element` is used
     * @return {jQuery}          jQuery object containing matched DOM elements
     */
    findElement(selector, $parent) {
      $parent = $parent !== undefined ? $parent : this.$element;
      return $parent ? $parent.find(selector) : $(selector);
    },

    /**
     * Find a DOM element within the StoreFinder
     * @param  {string} selector Selector
     * @return {jQuery}          jQuery object containing matched DOM elements
     */
    findStoreFinderElement(selector) {
      return this.findElement(selector, this.settings.$storeFinder);
    },

    /**
     * Request user location
     */
    getUserLocation() {
      document.jq.trigger(EventTypes.STOREFINDER_GET_USER_LOCATION_REQUEST);
    },

    /**
     * Load stores from server
     */
    loadStores(event, parameters) {
      Logger.debug(this.module, 'loadStores', parameters || '');
      this.$storeFinder.trigger(EventTypes.STOREFINDER_GET_STORES_REQUEST, parameters);
    },

    /**
     * Load nearest stores from server
     * @param  {string} longitude   GPS Longitude coordinate
     * @param  {string} latitude    GPS Latitude coordinate
     * @param  {object} parameters  Search parameters, optional object
     */
    loadStoresByCoordinates(event, eventData) {
      const requestData = $.extend({}, eventData.parameters, {
        longitude: eventData.longitude,
        latitude: eventData.latitude,
      });

      this.$storeFinder.trigger(EventTypes.STOREFINDER_GET_STORES_REQUEST, requestData);
    },
  });

export default StoreFinderComponent;
