/* global AppSettings */
/**
 * Storefinder
 *
 * @author  Meinaart van Straalen
 * @author Tamara Bazko
 */

import $ from 'jquery';
import Mustache from 'mustache';
import EventTypes from 'components/EventTypes';
import Singleton from 'components/utils/Singleton';
import createLogger from 'components/logger/Logger';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import storeInfoWindowTemplate from './templates/storeShortInfo.html';
import device from 'components/utils/device';
import StoreFinderComponent from './StoreFinderComponent';
import 'components/domutils/Element.jQuery';
import 'jquery.extendPrototype';
import 'jquery.resizeEvents';
import 'jquery.stickyTrigger';
import 'jquery.pageshowEvents';

const Logger = createLogger('MapManager');
const $document = document.jq;
const $window = window.jq;

function MapManager(settings) {
  Logger.info('Created');

  StoreFinderComponent.call(this, settings, module);
  this.Map = this.settings.Map;
  this.$map = this.findElement(this.settings.mapSelector);
  this.$zoomControls = this.findElement(this.settings.zoomControlsSelector);
  this.$gpsButton = this.$zoomControls.find(this.settings.gpsButtonSelector);
  this.$zoomInButton = this.$zoomControls.find(this.settings.zoomInButtonSelector);
  this.$zoomOutButton = this.$zoomControls.find(this.settings.zoomOutButtonSelector);
  this.onResize();
  this.bindEvents();
  this.bindMarkerEvents();

  this.loadMap()
    .fail(
      function (error) {
        this.$element.stickyTrigger(EventTypes.STOREFINDER_MAP_FAILED, {
          error,
        });
      }.bind(this)
    )
    .done(
      function () {
        this.mapCanvas = this.Map.mapCanvas;
        this.mapCanvasBounds = this.Map.mapCanvasBounds;
        this.offsetLeft = this.Map.getOffsetLeft();

        this.$element.stickyTrigger(EventTypes.STOREFINDER_MAP_INITIALIZED, {
          map: this.mapCanvas,
        });
        this.getUserLocation();
      }.bind(this)
    );
}

$.extendPrototype(MapManager, StoreFinderComponent.prototype, {
  $content: undefined,
  $gpsButton: undefined,
  $map: undefined,
  $zoomControls: undefined,
  $zoomInButton: undefined,
  $zoomOutButton: undefined,
  activeMarker: undefined,
  brandIcon: undefined,
  directionsRouteBounds: undefined,
  infoWindow: undefined,
  isVisible: false,
  lockMapBounds: false,
  mapCanvas: undefined,
  mapCanvasBounds: undefined,
  markerIcon: undefined,
  storedStoresData: undefined,
  offsetLeft: undefined,

  settings: {
    gpsButtonSelector: '.js-storeFinder-map-buttonGPS',
    mapSelector: '.js-storeFinder-map-map',
    selector: '.js-storeFinder-map-container',
    zoomControlsSelector: '.js-storeFinder-map-zoomControls',
    zoomInButtonSelector: '.js-storeFinder-map-buttonZoomIn',
    zoomOutButtonSelector: '.js-storeFinder-map-buttonZoomOut',
    Map: undefined,
    fullPage: true,
    draggable: true,
  },

  storeMarkers: {},
  activeStoreMarkers: {},
  markersForClustering: [],
  stores: [],
  storesUpdated: undefined,

  // Zoom level for user's location
  zoomLevelUserLocation: 12,

  // Zoom level when zooming in on a marker
  zoomLevelMarker: 14,

  /**
   * Bind events
   */
  bindEvents() {
    $document
      .on(EventTypes.STOREFINDER_GET_STORES, this.getStores.bind(this))
      .on(EventTypes.STOREFINDER_GOT_STORES_DATA, this.onGotStoresData.bind(this))
      .on(EventTypes.STOREFINDER_GET_USER_LOCATION, this.onGetUserLocation.bind(this))
      .on(EventTypes.STOREFINDER_GET_USER_LOCATION_ERROR, this.onGetUserLocationFailed.bind(this))
      .on(EventTypes.STOREFINDER_HIDE_MAP_REQUEST, this.setVisibility.bind(this, false))
      .on(EventTypes.STOREFINDER_SHOW_MAP_REQUEST, this.setVisibility.bind(this, true))
      .on(EventTypes.STOREFINDER_STORE_DESELECTED, this.onStoreSelected.bind(this))
      .on(EventTypes.STOREFINDER_STORE_SELECTED, this.onStoreSelected.bind(this))
      .on(EventTypes.STOREFINDER_STORE_DETAILS_OPEN, this.storeActivate.bind(this))
      .on(EventTypes.STOREFINDER_STORE_DETAILS_CLOSE, this.storeActivate.bind(this))
      .on(EventTypes.STOREFINDER_STORES_FILTERED, this.onGotStoresData.bind(this))
      .on(EventTypes.STOREFINDER_MAP_BOUNDS_CHANGED, this.resolveTriggerMapResize.bind(this, true))
      .on(EventTypes.STOREFINDER_LOAD_STORES, this.loadStores.bind(this))
      .on(
        EventTypes.STOREFINDER_LOAD_STORES_BY_COORDINATES,
        this.loadStoresByCoordinates.bind(this)
      )
      .on(EventTypes.DIRECTIONS_ROUTE_DISPLAYED, this.onDirectionsRouteDisplayed.bind(this));

    $window.on(EventTypes.SIDENAV_ANIMATION_COMPLETE, this.onResize.bind(this));
    this.$gpsButton.on('vclick.getUserLocation', this.onGpsButtonClick.bind(this));
    this.$zoomInButton.on('vclick', this.changeZoom.bind(this, 1));
    this.$zoomOutButton.on('vclick', this.changeZoom.bind(this, -1));
    if (!matchBreakpoint(this.settings.breakpointOverlay)) {
      window.jq.on('pageshow', this.onPageShow.bind(this));
    }
  },

  /*
   * If back button pressed, load list.
   * iOS7 fires 'pageshow' event on tab focus,
   * so use cautiously
   */
  onPageShow() {
    if (
      this.activeMarker ||
      (!matchBreakpoint(this.settings.breakpointOverlay) && this.isVisible)
    ) {
      return;
    } else {
      this.$element.trigger(EventTypes.STOREFINDER_SHOW_LIST_REQUEST);
    }
  },

  /**
   * Bind events for each marker
   */
  bindMarkerEvents() {
    $window
      .off(EventTypes.STOREFINDER_MARKER_CLICK)
      .on(EventTypes.STOREFINDER_MARKER_CLICK, this.onMarkerClick.bind(this));

    if (!device.hasTouch && matchBreakpoint(this.settings.breakpointOverlay)) {
      $window
        .off(EventTypes.STOREFINDER_MARKER_MOUSEOVER)
        .off(EventTypes.STOREFINDER_MARKER_MOUSEOUT)
        .on(EventTypes.STOREFINDER_MARKER_MOUSEOVER, this.onMarkerHover.bind(this))
        .on(EventTypes.STOREFINDER_MARKER_MOUSEOUT, this.onMarkerHover.bind(this));
    }
  },

  /**
   * Change the zoom
   * @param  {int} zoomChange Can be 1 (zoom in) or -1 (zoom out)
   */
  changeZoom(zoomChange) {
    if (!isNaN(zoomChange)) {
      zoomChange = Math.max(-1, Math.min(zoomChange, 1));
      const zoomLevel = this.Map.getMapZoom() + zoomChange;
      this.Map.setMapZoom(zoomLevel);
    }
  },

  /**
   * Close infoWindow with store details
   */
  closeInfoWindow() {
    if (device.hasTouch || !matchBreakpoint(this.settings.breakpointOverlay)) {
    } else if (this.infoWindow) {
      this.Map.closeInfoWindow();
    }
  },

  /**
   * Update distance from user location to stores OR from searched place to stores
   * @param  {Object}   coordinates     Latitude and longitude
   */
  distanceUpdate(coordinates) {
    if (this.stores.length !== 0) {
      this.Map.distanceUpdate(this.stores, coordinates);
    }
  },

  /**
   * Get map size in string format
   * @return {string} width x height
   */
  getMapSize() {
    return `${this.$map.width()}x${this.$map.height()}`;
  },

  /**
   * Load API of the map being used
   *
   * @returns {jQuery.Deferred}
   * @private
   */
  loadMap() {
    Logger.debug('Loading Maps API');

    const settings = {
      $map: this.$map,
      $gpsButton: this.$gpsButton,
      draggable: this.settings.draggable,
    };

    return this.Map.loadMap(settings);
  },

  /**
   * If route is active getting route's LatLngBounds to fit map correctly on resize,
   * getting undefined if directions overlay is closed
   * @param    {Object}    event   jQuery event object
   * @param    {Object}    eventData    property: {bounds}
   */
  onDirectionsRouteDisplayed(event, eventData) {
    if (eventData) {
      this.directionsRouteBounds = eventData.bounds;
      this.$gpsButton.off('vclick.getUserLocation');

      if (!eventData.bounds) {
        this.bindMarkerEvents();
        this.$gpsButton.on('vclick.getUserLocation', this.onGpsButtonClick.bind(this));
      }
    }
  },

  /**
   * When got and sorted (if needed) stores on each concrete map
   * @param  {object} event     jQuery event object
   * @param  {object} eventData Data belonging to event
   */
  getStores(event, eventData) {
    Logger.debug(module, 'getStores:', eventData);
    if (eventData && eventData !== this.storedStoresData) {
      this.storedStoresData = eventData;
      this.stores = this.storedStoresData.stores;
      $document
        .off(EventTypes.STOREFINDER_SHOW_MAP_REQUEST)
        .on(EventTypes.STOREFINDER_SHOW_MAP_REQUEST, this.setVisibility.bind(this, true))
        .one(EventTypes.STOREFINDER_SHOW_MAP_REQUEST, this.getStores.bind(this));
    }

    if (this.isVisible || matchBreakpoint(this.settings.breakpointOverlay)) {
      if (this.stores.length > 0) {
        this.updateStoreMarkers(this.stores);
      } else {
        this.removeStoreMarkers(this.stores);
        if (this.locationBounds) {
          this.Map.fitBounds(this.locationBounds);
        } else if (this.userLatLng) {
          this.Map.setMapZoom(this.zoomLevelUserLocation);
          this.Map.setMapCenter(this.userLatLng);
        } else {
          const countryName =
            this.storedStoresData && this.storedStoresData.country
              ? this.storedStoresData.country
              : AppSettings.countryName;
          this.Map.focusOnCountry(countryName);
        }
      }
    }
  },

  /**
   * When got data from apps.g-star.com
   * @param  {object} event     jQuery event object
   * @param  {object} eventData Data belonging to event
   */
  onGotStoresData(event, eventData) {
    Logger.debug('onGotStoresData:', eventData);

    // In case of back button,
    // if marker is active (cache case), keeps the map at same spot
    if (this.activeMarker) {
      return;
    }
    this.Map.setStores.call(this.Map, eventData);
  },

  /**
   * If user location available and gps button clicked
   */
  onGpsButtonClick() {
    this.$gpsButton.addClass('is-loading');
    this.getUserLocation();
  },

  /**
   * Event listener for when user OR searched place location is successfully retrieved
   * @param  {object} event     jQuery event object
   * @param  {object} eventData Data belonging to event
   */
  onGetUserLocation(event, eventData) {
    Logger.debug('onGetUserLocation', eventData);
    const place = eventData.place ? eventData.place : false;

    this.locationBounds = undefined;
    this.userLatLng = undefined;
    this.activeMarker = undefined;

    if (eventData.country) {
      if (place && place.geometry && place.geometry.viewport) {
        this.locationBounds = place.geometry.viewport;
      }
      this.Map.loadStores({
        country: eventData.country,
      });
    } else if (eventData.coordinates) {
      const coords = eventData.coordinates;
      const latLng = this.Map.getUserLatLng(coords.latitude, coords.longitude);
      this.distanceUpdate(coords);
      if (latLng) {
        if (place) {
          this.searchLatLng = latLng;
          if (place.geometry && place.geometry.viewport) {
            this.locationBounds = place.geometry.viewport;
          }
        } else {
          this.userLatLng = latLng;
        }

        if (eventData.searchParameters && eventData.searchParameters.country) {
          this.Map.loadStores(eventData.searchParameters);
        } else {
          const data = {
            longitude: coords.longitude,
            latitude: coords.latitude,
            parameters: eventData.searchParameters,
          };
          this.Map.loadStoresByCoordinates(data);
        }
      }
    }
    this.$gpsButton.removeClass('is-loading');
  },

  /**
   * Handler for when GPS coordinates cannot be retrieved this.
   */
  onGetUserLocationFailed(event, eventData) {
    this.onGetUserLocation(event, {
      country: AppSettings.country,
      countryName: AppSettings.countryName,
    });
    this.$gpsButton.removeClass('is-loading');
  },

  /**
   * Event handler for when a store marker is clicked
   * @param {object}             store  Data of store
   * @param {object}             marker  Active marker
   */
  onMarkerClick(event, eventData) {
    const eventType = this.activeMarker
      ? EventTypes.STOREFINDER_STORE_DETAILS_CLOSE_REQUEST
      : EventTypes.STOREFINDER_STORE_DETAILS_OPEN_REQUEST;
    eventData.fromMap = true;

    this.$map.trigger(eventType, eventData);
    this.storeActivate(event, eventData);
  },

  /**
   * Event handler for marker's mouseover and mouseout events
   * @param {object}             store  Data of store
   * @param {object}             marker  Hovered marker
   */
  onMarkerHover(event, eventData) {
    let { marker } = eventData;
    const { store } = marker;

    marker = marker || this.storeMarkers[store.store_id];
    const isSelected = event.type === 'storeFinderMarkerMouseOver';
    const eventType = isSelected
      ? EventTypes.STOREFINDER_STORE_SELECTED
      : EventTypes.STOREFINDER_STORE_DESELECTED;

    if (isSelected) {
      this.openInfoWindow(store, marker);
    } else {
      this.closeInfoWindow();
    }

    this.$element.trigger(eventType, {
      store,
      isSelected,
      fromMap: true,
    });
  },

  /**
   * Handle resize events
   */
  onResize(e) {
    // This important for touch devices which have native hide/show bars,
    // they trigger resize on scroll, when appear/disappear and it's not needed in this case
    if (matchBreakpoint(this.settings.breakpointOverlay)) {
      $window.one('resizeEnd', this.onResize.bind(this));
    } else {
      $window.one('resizeWidthEnd', this.onResize.bind(this));
    }

    const contentHeight = AppSettings.topNavigationHeight;

    if (matchBreakpoint(this.settings.breakpointOverlay)) {
      if (this.settings.fullPage) {
        this.$element.height(window.innerHeight - contentHeight);
      }
    } else {
      if (this.$element.attr('style')) {
        this.$element.attr('style', '');
      }
      $document
        .off(EventTypes.STOREFINDER_SHOW_MAP_REQUEST)
        .on(EventTypes.STOREFINDER_SHOW_MAP_REQUEST, this.setVisibility.bind(this, true))
        .one(EventTypes.STOREFINDER_SHOW_MAP_REQUEST, this.getStores.bind(this));

      if (this.mapCanvasBounds) {
        this.Map.fitBounds(this.mapCanvasBounds);
      }
    }

    this.setVisibility(this.isVisible);

    this.triggerMapResize().done(
      function () {
        let marker;
        if (!this.mapCanvasBounds) {
          if (this.activeMarker) {
            marker = this.activeMarker;
            this.getStores('', this.storedStoresData);
          }
        }

        if (this.directionsRouteBounds) {
          this.Map.fitBounds(this.directionsRouteBounds);
        } else if (!this.activeMarker) {
          this.getStores('', this.storedStoresData);
        } else {
          marker = this.activeMarker;
          this.activeMarker = undefined;
          this.setActiveMarker(marker);
          this.setVisibility(true);
          this.Map.mapPanTo(this.Map.getMarkerPosition(marker));
        }
      }.bind(this)
    );
  },

  /**
   * Handler when a store is selected.
   * @param  {object} event     jQuery Event object
   * @param  {object} eventData Object containing `store` property with store data.
   */
  onStoreSelected(event, eventData) {
    if (eventData && !eventData.fromMap) {
      if (eventData.store && eventData.isSelected) {
        const { store } = eventData;
        const marker = this.storeMarkers[store.store_id];
        if (this.Map.getMarkerClusterer()) {
          if (this.Map.getMapZoom() >= 12) {
            this.openInfoWindow(store, marker);
            if (!this.Map.fitVisibleBounds(marker)) {
              this.Map.mapPanTo(this.Map.getMarkerPosition(marker));
            }
          }
        } else {
          if (!this.Map.fitVisibleBounds(marker)) {
            this.Map.mapPanTo(this.Map.getMarkerPosition(marker));
          }
          this.openInfoWindow(store, marker);
        }
      } else {
        this.closeInfoWindow();
      }
    }
  },

  /**
   * Opens infoWindow with store data
   * @param {object}     store   Data of store
   * @param {object}     marker  Marker to which infoWindow is bound
   */
  openInfoWindow(store, marker) {
    if (device.hasTouch || !matchBreakpoint(this.settings.breakpointOverlay)) {
      return;
    }

    this.closeInfoWindow();

    const storeData = {
      distanceUnit: window.labels.storeFinder.distanceUnit,
      store,
      labels: window.labels?.storeFinder || {},
    };

    const storeInfoWindowContent = Mustache.render(storeInfoWindowTemplate, storeData);

    if (this.infoWindow) {
      this.Map.setInfoWindowContent(this.infoWindow, storeInfoWindowContent);
      this.Map.openInfoWindow(marker);
    } else {
      this.infoWindow = this.Map.getInfoWindow(
        marker,
        storeInfoWindowContent,
        this.settings.breakpointOverlay
      );
      this.Map.openInfoWindow(marker);
    }

    this.infoWindow = this.Map.getInfoWindow(marker);
  },

  /**
   * Draw marker on map
   * @param  {object} store Object containing store data
   */
  plotStore(store) {
    let marker;

    if (this.storeMarkers[store.store_id]) {
      marker = this.storeMarkers[store.store_id];
    } else {
      marker = this.Map.getStoreMarker(store);
      this.storeMarkers[store.store_id] = marker;
    }

    this.activeStoreMarkers[store.store_id] = marker;

    this.markersForClustering.push(marker);
    marker.store = store;
    marker.content = store.addressHtml;
    this.Map.setMap(marker, this.mapCanvas);
    const markerPos = this.Map.getMarkerPosition(marker);
    this.mapCanvasBounds = this.Map.extendBounds(markerPos);

    // Put brand stores on top
    if (store.isBrandStore) {
      const zIndex = this.Map.getMarkerMaxZIndex();
      this.Map.setMarkerZIndex(marker, zIndex + 1);
    }
  },

  /**
   * Hide all store markers.
   * @param {Array} stores List of stores that should be kept
   */
  removeStoreMarkers(stores) {
    const storeIds = [];

    stores.forEach(function (store) {
      storeIds.push(store.store_id);
    });

    // Unset active marker
    if (this.activeMarker) {
      this.setActiveMarker();
    }

    if (this.storeMarkers) {
      for (const storeId in this.storeMarkers) {
        if (stores.length === 0) {
          this.Map.setMap(this.storeMarkers[storeId], null);
        } else if (storeIds.indexOf(storeId) === -1) {
          this.Map.setMap(this.storeMarkers[storeId], null);
        }
      }

      this.storeMarkers = this.Map.removeStoreMarkers(this.storeMarkers);
    }
  },

  /**
   * Resolve deferred generated by `this.triggerMapResize`.
   *
   * @param {boolean} resolve If true deferred is resolved, otherwise rejected
   */
  resolveTriggerMapResize(resolve) {
    if (this.triggerMapResizeDeferred) {
      clearTimeout(this.triggerMapResizeDeferredTimeout);

      if (resolve) {
        this.triggerMapResizeDeferred.resolve();
      } else {
        this.triggerMapResizeDeferred.reject();
      }

      this.lastMapSize = this.getMapSize();
      delete this.triggerMapResizeDeferred;
    }
  },

  /**
   * Set active marker
   * @param  {object} Marker    maps marker that needs to become active
   * @param {boolean} zoom
   */
  setActiveMarker(marker) {
    const prevActiveId = (this.activeMarker && this.activeMarker.store.store_id) || undefined;

    if (marker && this.activeMarker && marker.store.store_id === prevActiveId) {
      this.activeMarker = undefined;
      this.Map.activeMarker = undefined;
      this.Map.setMarkersVisibility(this.activeStoreMarkers, true);
      this.Map.fitBounds(this.mapCanvasBounds);
    }

    if (marker && marker.store.store_id !== prevActiveId) {
      const zIndex = this.Map.getMarkerMaxZIndex();
      const markerPos = this.Map.getMarkerPosition(marker);
      this.isVisible = true;
      this.activeMarker = marker;
      this.Map.activeMarker = this.activeMarker;
      this.Map.setMarkerZIndex(this.activeMarker, zIndex + 1);
      this.Map.setMarkersVisibility(this.activeStoreMarkers, false);

      this.Map.setMarkersVisibility([this.activeMarker], true);
      this.Map.setMapZoom(this.zoomLevelMarker);

      this.Map.mapPanTo(markerPos);
    }
  },

  /**
   * Activate or disactivate store this means:
   * zoom on marker
   * set marker as map center
   * hide all other marker
   * trigger event about marker state
   *
   * @param {event}      event
   * @param {object}     eventData
   */
  storeActivate(event, eventData) {
    let marker;

    // If list item or back trigger is clicked
    if (eventData.fromList === true) {
      const store = eventData.store || (eventData.marker && eventData.marker.store) || undefined;

      marker = (store && this.storeMarkers[store.store_id]) || undefined;
    } else if (eventData.fromMap === true) {
      // If marker clicked
      marker = eventData.marker;
    } else {
      return;
    }

    // Activate or disactivate marker
    this.setActiveMarker(marker);
  },

  /**
   * Toggle map visibility
   * @param  {boolean}  value
   * @param  {boolean} resize If true or omited the map is resized to it's correct dimensions.
   */
  setVisibility(visible) {
    Logger.debug('setVisibility:', visible);

    if (!matchBreakpoint(this.settings.breakpointOverlay)) {
      this.isVisible = visible;
      this.$element.toggleClass('is-hidden--xsmall', !this.isVisible);
    }

    if (visible) {
      if (!matchBreakpoint(this.settings.breakpointOverlay)) {
        this.Map.triggerAPIevent(this.mapCanvas, 'resize');
      }

      this.triggerMapResize().always(
        function () {
          Logger.debug(module, 'setVisibility: Update map after resize');
          if (this.directionsRouteBounds) {
            this.Map.fitBounds(this.directionsRouteBounds);
          } else if (this.activeMarker) {
            const marker = this.activeMarker;
            this.activeMarker = undefined;
            this.setActiveMarker(marker);
          }
        }.bind(this)
      );
    }
  },

  /**
   * Triggers resize event on map
   */
  triggerMapResize() {
    const deferred = $.Deferred();
    const mapSize = this.getMapSize();

    if (this.mapCanvas) {
      clearTimeout(this.triggerMapResizeDeferredTimeout);

      if (this.triggerMapResizeDeferred) {
        deferred.fail(this.triggerMapResizeDeferred.reject.bind(this.triggerMapResizeDeferred));
        deferred.done(this.triggerMapResizeDeferred.resolve.bind(this.triggerMapResizeDeferred));
        delete this.triggerMapResizeDeferred;
      }
      if (mapSize === this.lastMapSize) {
        deferred.reject();
      } else {
        this.triggerMapResizeDeferred = deferred;
        this.Map.triggerAPIevent(this.mapCanvas, 'resize');
        this.triggerMapResizeDeferredTimeout = setTimeout(
          this.resolveTriggerMapResize.bind(this, matchBreakpoint(this.settings.breakpointOverlay)),
          250
        );
      }
    }

    return deferred.promise();
  },

  /**
   * Add stores to the map or update them, also reset boundaries
   * @param  {Array} stores Array with store data objects
   */
  updateStoreMarkers(stores) {
    Logger.debug('updateStoreMarkers');

    this.mapCanvasBounds = this.Map.getLatLngBounds();

    // Remove store markers that are not in stores
    this.removeStoreMarkers(stores);

    for (const key in this.activeStoreMarkers) {
      delete this.activeStoreMarkers[key];
    }

    this.activeStoreMarkers = {};

    // Show markers that are in stores array
    stores.forEach(this.plotStore.bind(this));

    if (this.searchLatLng) {
      this.Map.extendBounds(this.searchLatLng);
    }

    this.Map.setClustering(this.markersForClustering);
    this.markersForClustering.length = 0;
    this.Map.fitBounds(this.mapCanvasBounds);

    this.locationBounds = undefined;
    this.lockMapBounds = false;
  },
});

export default Singleton.create(MapManager, 'MapManager');
