/**
 * Contains utilities for GPS calculations.
 *
 * @author Meinaart van Straalen
 */

  const exports = {};

  /**
   * Converts degrees to radians
   * @param  {number} deg Degrees
   * @return {number}     Radians
   */
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  const EARTH_RADIUS = 6371; // Radius of the earth in km
  const DEFAULT_UNIT = 'km';
  const MILES_MULTIPLIER = 0.621371;

  /**
   * Get distance between 2 GPS coordinates in KM
   * @param  {object} coord1 Object containing latitude and longitude
   * @param  {object} coord2 Object containing latitude and longitude
   * @param  {string} unit (optional) Unit, can be 'km' or 'miles', default is 'km'
   * @return {number}      Distance in KM between 2 sets of GPS coordinates
   */
  exports.getDistance = function getDistance(coord1, coord2, unit) {
    unit = unit || DEFAULT_UNIT;

    const lat1 = coord1.latitude;
    const lat2 = coord2.latitude;
    const lon1 = coord1.longitude;
    const lon2 = coord2.longitude;

    // Convert to radians
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let result = EARTH_RADIUS * c; // Distance in km

    if (unit.toLowerCase().indexOf('km') === -1) {
      result *= MILES_MULTIPLIER;
    }

    return result;
  };

  /**
   * Set `distance` and `distanceFormatted` property on `target` containing
   * distance in KM from supplied `origin`
   *
   * @param {object}  origin     Object containing `longitude` and `latitude` properties
   * @param {object}  target     Object with `longitude` and `latitude` properties
   * @param {string}  unit       (optional) Unit, can be 'km' or 'miles', default is 'km'
   * @return {object}            Processed object
   */
  exports.setDistance = function setDistance(origin, target, unit) {
    target.distance = exports.getDistance(origin, target, unit);
    target.distanceFormatted =
      target.distance > 1000
        ? Math.round(target.distance)
        : (Math.round(target.distance * 100) / 100).toFixed(1);
    return target;
  };

  /**
   * Set `distance` and `distanceFormatted` property on each item of `collection` containing
   * distance in KM from supplied `origin`
   *
   * @param {object} origin     Object containing `longitude` and `latitude` properties
   * @param {array}  collection Array containing objects with `longitude` and `latitude` properties
   * @param {string} unit       (optional) Unit, can be 'km' or 'miles', default is 'km'
   * @return {Array}            Processed collection
   */
  exports.setDistances = function updateDistances(origin, collection, unit) {
    for (const key in collection) {
      exports.setDistance(origin, collection[key], unit);
    }
    return collection;
  };

  /**
   * Sort array with objects on `distance` property (descending)
   *
   * @param  {Array} collection Array with objects that have a `distance` property
   * @return {Array}            Array sorted on distance
   */
  exports.sortOnDistance = function sortOnDistance(collection) {
    return collection.sort(distanceCompare);
  };

  /**
   * Compare `distance` property of 2 objects. Meant to be used with `Array.sort`.
   *
   * @param  {object} item1 Object containing `distance` property
   * @param  {object} item2 Object containing `distance` property
   * @return {int}          Distance: equal: 0, smaller: -1, larger: 1
   */
  function distanceCompare(item1, item2) {
    if (item1.distance === item2.distance) {
      return 0;
    }
    return item1.distance > item2.distance ? 1 : -1;
  }

  export default exports;

